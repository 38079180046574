<template>
  <table style="width:100%;border:0;" class="pa-0 ma-0">
    <tr class="pa-0 ma-0">
      <td width="50" class="pa-0 ma-0">
        <v-progress-circular
          :size="35"
          :width="6"
          :value="display_uptime"
          :color="computed_color"
          class="ma-2"
        >
          <span
            v-if="display_uptime != null"
            style="font-size:.675rem; color:black !important;"
          >
            {{ display_uptime }}</span
          >
          <span v-else>?</span>
        </v-progress-circular>
      </td>
      <td class="pa-0 ma-0">
        {{ url.issuer_uri }}
        Issuer Name: {{ url.issuer_fqdn.friendly_name }}
        <br />
        Issuer Key: {{ url.issuer_public_key }}
        <br />

        <template v-if="url.issuer_uri.is_private">
          <v-btn
            v-if="unavailable"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="red"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> PRIVATE</v-btn
          >
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            x-small
            color="success"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> PRIVATE</v-btn
          >
        </template>

        <template v-if="url.issuer_uri.is_public">
          <v-btn
            v-if="display_uptime === null"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="black"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            PUBLIC</v-btn
          >
          <v-btn
            v-else-if="unavailable"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="red"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-down-thick</v-icon> PUBLIC</v-btn
          >
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            x-small
            color="success"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> Public</v-btn
          >
        </template>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "OcspIssuer",
  props: ["url"],
  data: function() {
    return {
      remote: false,
      loading: false
    };
  },
  computed: {
    display_uptime: function() {
      if (this.url.issuer_uri.thirty_day_uptime != null) {
        return Math.round(this.url.issuer_uri.thirty_day_uptime * 1000) / 10;
      } else if (this.url.issuer_uri.public_thirty_day_uptime != null) {
        return (
          Math.round(this.url.issuer_uri.public_thirty_day_uptime * 1000) / 10
        );
      } else {
        return null;
      }
    },
    unavailable: function() {
      if (this.url.issuer_uri.current_groups) {
        return (
          this.url.issuer_uri.current_groups.filter(function(value) {
            return value.available === false;
          }).length > 0
        );
      } else {
        return null;
      }
    },
    public_groups: function() {
      if (this.url.issuer_uri.current_groups) {
        return this.url.issuer_uri.current_groups.filter(function(value) {
          return value.sensor === 2;
        });
      } else {
        return [];
      }
    },
    private_groups: function() {
      if (this.url.issuer_uri.current_groups) {
        return this.url.issuer_uri.current_groups.filter(function(value) {
          return value.sensor !== 2;
        });
      } else {
        return [];
      }
    },
    computed_color: function() {
      let time = this.display_uptime;
      if (time === 100) {
        return "#008000";
      } else if (time >= 99.99) {
        return "#91cf60";
      } else if (time >= 99.9) {
        return "#d9ef8b";
      } else if (time >= 99) {
        return "#fee08b";
      } else if (time >= 90) {
        return "#fc8d59";
      } else {
        return "#d73027";
      }
    }
  },
  mounted: function() {},
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style scoped></style>
