<template>
  <v-row class="pa-2">
    <v-col cols="12" md="7">
      <v-card>
        <v-card-title v-if="url" class="mb-0 pb-0">
          {{ url.uri }}
          <v-btn :href="url.uri" icon color="primary" target="_blank"
            ><v-icon>mdi-open-in-new</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text v-if="last_group" class="mt-0 pt-0">
          <strong>Last checked:</strong>
          {{ last_group.last_time | moment("from", "now") }} from
          {{ last_group.sensor }}
          <br />
          <template v-if="last_group.available">
            <status-indicator positive pulse class="mr-2"></status-indicator>
            <strong>Online: </strong> {{ last_group.status }} ({{
              last_group.reason
            }}) <strong>Server: </strong> {{ last_group.server_type }}
          </template>
          <template v-else>
            <status-indicator negative pulse class="mr-2"></status-indicator>
            <strong>Offline: </strong> {{ last_group.reason }}
          </template>
        </v-card-text>
      </v-card>

      <template v-if="url">
        <PerIssuerOcspUris
          :urls="url.per_issuer_ocsp_uris"
          v-if="url.per_issuer_ocsp_uris.length > 0"
        >
        </PerIssuerOcspUris>
        <v-card class="mt-2" v-else>
          <UrlContentHistory
            :url="url"
            @load-events="load_events"
            :private_instance="private_instance"
            :local="local"
          ></UrlContentHistory>
        </v-card>
      </template>

      <v-row>
        <v-col cols="12" md="12" v-if="sources.certificate_aias">
          <MaterialCard
            color="secondary"
            v-if="sources.certificate_aias.length > 0"
          >
            <template v-slot:heading>
              <v-card-title v-if="url" class="mb-0 pa-2" style="font-size:1em;">
                AIA in Certificates
              </v-card-title>
            </template>
            <CertificateTable
              v-if="sources.certificate_aias"
              :certificates="sources.certificate_aias"
              default_page_size="5"
            ></CertificateTable>
          </MaterialCard>

          <MaterialCard
            color="secondary"
            v-if="sources.certificate_sias.length > 0"
          >
            <template v-slot:heading>
              <v-card-title v-if="url" class="mb-0 pa-2" style="font-size:1em;">
                SIA in Certificates
              </v-card-title>
            </template>
            <CertificateTable
              v-if="sources.certificate_sias"
              :certificates="sources.certificate_sias"
              default_page_size="5"
            ></CertificateTable>
          </MaterialCard>

          <MaterialCard
            color="secondary"
            v-if="sources.certificate_crls.length > 0"
          >
            <template v-slot:heading>
              <v-card-title v-if="url" class="mb-0 pa-2" style="font-size:1em;">
                CRL in Certificates
              </v-card-title>
            </template>
            <CertificateTable
              v-if="sources.certificate_crls"
              :certificates="sources.certificate_crls"
              default_page_size="5"
            ></CertificateTable>
          </MaterialCard>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="5">
      <v-card v-if="!loading">
        <template>
          <v-progress-circular
            :size="75"
            :width="10"
            :value="display_uptime"
            color="teal"
            class="ma-2"
          >
            <span v-if="display_uptime"> {{ display_uptime }}</span>
            <span v-else>?</span>
          </v-progress-circular>
        </template>
        {{ display_uptime }}% up-time in the last 30 days
      </v-card>
      <CurrentContents
        v-if="last_available_group"
        :last_available_group="last_available_group"
        :local="local"
      />
      <v-card
        v-else-if="!last_available_group_loading"
        class="v-card--material mt-2"
        color="#888"
        dark
      >
        <v-card-title style="font-size:1em;"
          >No Known Previous File</v-card-title
        ></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import UserService from "../store/UserService";
import UrlContentHistory from "../components/urls/ContentHistory";
import CertificateTable from "../components/CertificateTable";
import MaterialCard from "../components/charts/MaterialCard";
import { StatusIndicator } from "vue-status-indicator";
import CurrentContents from "../components/urls/CurrentContents";
import PerIssuerOcspUris from "../components/urls/PerIssuerOcspUris";

function isInt(value) {
  var x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
}
export default {
  name: "Url",
  components: {
    PerIssuerOcspUris,
    CurrentContents,
    MaterialCard,
    CertificateTable,
    UrlContentHistory,
    StatusIndicator
  },
  data: function() {
    return {
      id: this.$route.params.id,
      url: null,
      sources: [],
      loading: true,
      last_group: null,
      last_available_group: null,
      last_available_group_loading: true
    };
  },
  mounted() {
    this.loadUrlDetails();
  },
  computed: {
    local() {
      if (this.private_instance === this.url.is_private) {
        return true;
      } else if (!this.private_instance === this.url.is_public) {
        return true;
      }
      return false;
    },
    private_instance() {
      return this.$store.getters.instance.private;
    },
    display_uptime: function() {
      if (!this.url) {
        return null;
      }
      if (this.url.per_issuer_ocsp_uris.length > 0) {
        let issuers_with_data = this.url.per_issuer_ocsp_uris.filter(function(
          value
        ) {
          return (
            value.issuer_uri.thirty_day_uptime ||
            value.issuer_uri.public_thirty_day_uptime
          );
        });
        let count = issuers_with_data.length;
        let sum = this.url.per_issuer_ocsp_uris
          .map(e =>
            Math.max(
              e.issuer_uri.thirty_day_uptime,
              e.issuer_uri.public_thirty_day_uptime
            )
          )
          .reduce((a, b) => a + b, null);
        return Math.round((sum / count) * 1000) / 10;
      }
      if (this.url.thirty_day_uptime != null) {
        return Math.round(this.url.thirty_day_uptime * 1000) / 10;
      } else if (this.url.public_thirty_day_uptime != null) {
        return Math.round(this.url.public_thirty_day_uptime * 1000) / 10;
      } else {
        return null;
      }
    },
    ldap_files() {
      let group = this.last_available_group;
      let result = { certs: [], crls: [] };
      for (let x in group.files) {
        result["certs"] = result["certs"].concat(
          group.files[x].filecertificates_set.map(e => e.certificate)
        );
        result["crls"] = result["crls"].concat(
          group.files[x].filecrls_set.map(e => e.certificate)
        );
      }
      console.log(result);
      return result;
    }
  },
  methods: {
    load_events: function(data) {
      this.last_group = data[0];
      if (data[0].available) {
        this.last_available_group = data[0];
      } else if (data[1]) {
        this.last_available_group = data[1];
      } else {
        this.last_available_group = null;
      }
      this.last_available_group_loading = false;
    },
    loadSources: function() {
      UserService.getLocalApi("uri-sources/?sha1=" + this.id).then(response => {
        this.sources = response.data;
      });
    },
    loadUrlDetails: function() {
      if (isInt(this.id)) {
        // Get by ID
        UserService.getLocalApi("uris/" + this.id + "/").then(response => {
          this.url = response.data;
          this.loading = false;
          this.loadSources();
        });
      } else if (this.id.length === 40) {
        // Get by SHA 1
        UserService.getLocalApi("uris/?sha1=" + this.id).then(response => {
          this.url = response.data.results[0];
          this.loading = false;
          this.loadSources();
        });
      } else {
        // URL not found
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
