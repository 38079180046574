<template>
  <div>
    <v-data-table
      :headers="get_headers"
      dense
      :items="crls"
      class="elevation-0"
      flat
      style="border:0;"
      sort-by="crl.valid_to"
      :sort-desc="false"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 100]
      }"
      :mobile-breakpoint="0"
      :search="search"
      :hide-default-footer="crls.length < 6"
    >
      <template v-slot:item.sha1="{ item }">
        <CrlTile :crl="item" :local="local"></CrlTile>
      </template>

      <template v-slot:item.issuer_fqdn.friendly_name="{ item }">
        <Fqdn :value="item.issuer_fqdn"></Fqdn>
      </template>

      <template v-slot:item.this_update="{ item }">
        <span v-if="beforeNow(item.this_update)">
          {{ item.this_update | moment("MMM D YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.this_update | moment("MMM D YYYY") }}
        </span>
      </template>

      <template v-slot:item.next_update="{ item }">
        <span v-if="afterNow(item.next_update)">
          {{ item.next_update | moment("MMM D YYYY") }}
        </span>
        <span v-else style="color:red;font-weight:bold;">
          {{ item.next_update | moment("MMM D YYYY") }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import Fqdn from "./Fqdn";
import CrlTile from "./CrlTile";
export default {
  name: "CrlTable",
  components: { Fqdn, CrlTile },
  props: ["crls", "title", "search", "local"],
  data: function() {
    return {
      filter: "Show All",
      filters: ["Show All"],
      loading: false,
      options: { itemsPerPage: 10 },
      table_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Issuer",
          sortable: true,
          value: "issuer_fqdn.friendly_name"
        },
        {
          text: "This Update",
          sortable: true,
          width: 100,
          value: "this_update"
        },
        {
          text: "Next Update",
          sortable: true,
          width: 100,
          value: "next_update"
        }
      ],
      small_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Issuer",
          sortable: true,
          value: "issuer_fqdn.friendly_name"
        }
      ]
    };
  },
  computed: {
    get_headers: function() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.table_headers;
      } else {
        return this.small_headers;
      }
    }
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    }
  },
  mounted: function() {},
  watch: {}
};
</script>

<style scoped></style>
