var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border":"0"},attrs:{"headers":_vm.get_headers,"dense":"","items":_vm.crls,"flat":"","sort-by":"crl.valid_to","sort-desc":false,"footer-props":{
      'items-per-page-options': [5, 10, 25, 100]
    },"mobile-breakpoint":0,"search":_vm.search,"hide-default-footer":_vm.crls.length < 6},scopedSlots:_vm._u([{key:"item.sha1",fn:function(ref){
    var item = ref.item;
return [_c('CrlTile',{attrs:{"crl":item,"local":_vm.local}})]}},{key:"item.issuer_fqdn.friendly_name",fn:function(ref){
    var item = ref.item;
return [_c('Fqdn',{attrs:{"value":item.issuer_fqdn}})]}},{key:"item.this_update",fn:function(ref){
    var item = ref.item;
return [(_vm.beforeNow(item.this_update))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.this_update,"MMM D YYYY"))+" ")]):_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.this_update,"MMM D YYYY"))+" ")])]}},{key:"item.next_update",fn:function(ref){
    var item = ref.item;
return [(_vm.afterNow(item.next_update))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.next_update,"MMM D YYYY"))+" ")]):_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.next_update,"MMM D YYYY"))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }