var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MaterialCard',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-toolbar',{staticClass:"ma-0 pa-0",attrs:{"flat":"","color":"primary","dense":""}},[_c('v-toolbar-title',[_vm._v("Content History")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-radio-group',{staticClass:"mt-4",attrs:{"row":""},model:{value:(_vm.options.include),callback:function ($$v) {_vm.$set(_vm.options, "include", $$v)},expression:"options.include"}},[_c('strong',[_vm._v("Include: ")]),_c('v-radio',{staticClass:"ml-3",attrs:{"dense":"","hide-details":"","value":"available","label":"Available"}}),_c('v-radio',{attrs:{"value":"unavailable","label":"Unvailable"}}),_c('v-radio',{attrs:{"value":"both","label":"Both"}})],1),(_vm.options.include === 'available')?[_c('v-checkbox',{staticClass:"mt-4",attrs:{"label":"Changes Only"},model:{value:(_vm.options.changes_only),callback:function ($$v) {_vm.$set(_vm.options, "changes_only", $$v)},expression:"options.changes_only"}})]:_vm._e()],2)]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table_headers,"items":_vm.table_items,"options":_vm.options,"server-items-length":_vm.result_count,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
      'items-per-page-options': [5, 10, 25]
    }},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.available",fn:function(ref){
    var item = ref.item;
return [(item.available)?[_c('status-indicator',{attrs:{"positive":""}}),_vm._v(" "+_vm._s(item.status)+" ")]:[_c('status-indicator',{attrs:{"negative":""}}),_vm._v(" "+_vm._s(item.status)+" ")]]}},{key:"item.file",fn:function(ref){
    var item = ref.item;
return [(item.available)?[(item.file)?[(item.file.filecertificates_set.length > 0)?[(item.file)?_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.file.filecertificates_set.length)+" certificates ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-0 pt-4"},[_c('v-card-text',[_c('CertificateTable',{attrs:{"certificates":item.file.filecertificates_set.map(function (e) { return e.certificate; })}})],1)],1)],1):_vm._e()]:(item.file.filecrls_set.length > 0)?[_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" CRL #"+_vm._s(item.file.filecrls_set[0].crl.crl_number)+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},[_c('CrlTable',{attrs:{"crls":item.file.filecrls_set.map(function (e) { return e.crl; }),"local":_vm.local}})],1)],1)]:(item.file.file_type === 'p7c')?[_vm._v(" Empty P7C ")]:[_vm._v(" Unsupported Content ")]]:[(item.files.length > 0)?[_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.files.length)+" files ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-0 pt-4"},[_c('v-card-text',[_vm._l((item.files),function(f){return [_c('CertificateTable',{key:f.id,attrs:{"certificates":f.filecertificates_set.map(function (e) { return e.certificate; })}}),_c('CrlTable',{key:f.id,attrs:{"crls":f.filecrls_set.map(function (e) { return e.crl; }),"local":_vm.local}})]})],2)],1)],1)]:[_vm._v(" No Files ")]]]:[_c('strong',[_vm._v("Error "+_vm._s(item.status)+":")]),_vm._v(" "+_vm._s(item.reason)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"400px"}},[_vm._v(_vm._s(item.error_content))])])]]}},{key:"item.first_time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.first_time,"MMM D YYYY, h:mm:ss a"))+" ")]}},{key:"item.last_time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.last_time,"MMM D YYYY, h:mm:ss a"))+" ")]}},{key:"loading",fn:function(){return [_c('LoadingSVG')]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }