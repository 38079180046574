<template>
  <MaterialCard color="primary">
    <template v-slot:heading>
      <v-toolbar flat color="primary" class="ma-0 pa-0" dense>
        <v-toolbar-title class="ma-0 pa-0" style="font-size:1em;">
          Current Contents
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <v-card
      v-if="last_available_group"
      style="background-color: transparent;"
      flat
    >
      <v-card-text class="mt-0 pt-0" v-if="last_available_group.file">
        <strong>File Type:</strong>
        {{ last_available_group.file.file_type }}
        <span v-if="last_available_group.content_type">
          ({{ last_available_group.content_type }})</span
        >
        <CertificateTable
          v-if="last_available_group.file.filecertificates_set.length > 0"
          :local="local"
          :certificates="
            last_available_group.file.filecertificates_set.map(
              e => e.certificate
            )
          "
        ></CertificateTable>
        <template v-else-if="last_available_group.file.file_type === 'p7c'">
          <br />No Certificates
        </template>

        <CrlTable
          v-if="last_available_group.file.filecrls_set.length > 0"
          :local="local"
          :crls="last_available_group.file.filecrls_set.map(e => e.crl)"
        ></CrlTable>
      </v-card-text>

      <v-card-text
        class="mt-0 pt-0"
        v-else-if="last_available_group.files.length > 0"
      >
        <div class="pt-2">
          <CertificateTable
            :local="local"
            v-if="ldap_files.certs.length > 0"
            :certificates="ldap_files.certs"
          ></CertificateTable>

          <CrlTable
            :local="local"
            v-if="ldap_files.crls.length > 0"
            :crls="ldap_files.crls"
          ></CrlTable>
        </div>
      </v-card-text>
      <v-card-text v-else>
        No known previous files.
      </v-card-text>
    </v-card>
  </MaterialCard>
</template>

<script>
import MaterialCard from "../charts/MaterialCard";
import CrlTable from "../CrlTable";
import CertificateTable from "../CertificateTable";
export default {
  name: "CurrentContents",
  props: ["last_available_group", "local"],
  components: { CertificateTable, CrlTable, MaterialCard }
};
</script>

<style scoped></style>
