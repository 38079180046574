<template>
  <MaterialCard color="primary">
    <template v-slot:heading>
      <v-toolbar flat color="primary" class="ma-0 pa-0" dense>
        <v-toolbar-title>Content History</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-radio-group v-model="options.include" row class="mt-4">
          <strong>Include: </strong>
          <v-radio
            class="ml-3"
            dense
            hide-details
            value="available"
            label="Available"
          ></v-radio>
          <v-radio value="unavailable" label="Unvailable"></v-radio>
          <v-radio value="both" label="Both"></v-radio>
        </v-radio-group>
        <template v-if="options.include === 'available'">
          <v-checkbox
            v-model="options.changes_only"
            label="Changes Only"
            class="mt-4"
          ></v-checkbox
        ></template>
      </v-toolbar>
    </template>
    <v-data-table
      :headers="table_headers"
      :items="table_items"
      :options.sync="options"
      :server-items-length="result_count"
      :loading="loading"
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :footer-props="{
        'items-per-page-options': [5, 10, 25]
      }"
    >
      <template v-slot:top> </template>
      <template v-slot:item.id="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:item.available="{ item }">
        <template v-if="item.available">
          <status-indicator positive /> {{ item.status }}
        </template>
        <template v-else>
          <status-indicator negative /> {{ item.status }}
        </template>
      </template>
      <template v-slot:item.file="{ item }">
        <template v-if="item.available">
          <template v-if="item.file">
            <template v-if="item.file.filecertificates_set.length > 0">
              <v-dialog width="800" v-if="item.file">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    {{ item.file.filecertificates_set.length }} certificates
                  </v-btn>
                </template>

                <v-card class="pa-0 pt-4">
                  <v-card-text>
                    <CertificateTable
                      :certificates="
                        item.file.filecertificates_set.map(e => e.certificate)
                      "
                    ></CertificateTable>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-else-if="item.file.filecrls_set.length > 0">
              <v-dialog width="800">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    CRL #{{ item.file.filecrls_set[0].crl.crl_number }}
                  </v-btn>
                </template>
                <v-card class="pa-2" flat>
                  <CrlTable
                    :crls="item.file.filecrls_set.map(e => e.crl)"
                    :local="local"
                  ></CrlTable>
                </v-card>
              </v-dialog>
            </template>
            <template v-else-if="item.file.file_type === 'p7c'">
              Empty P7C
            </template>
            <template v-else>
              Unsupported Content
            </template>
          </template>
          <template v-else>
            <template v-if="item.files.length > 0">
              <v-dialog width="800">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    {{ item.files.length }} files
                  </v-btn>
                </template>

                <v-card class="pa-0 pt-4">
                  <v-card-text>
                    <template v-for="f in item.files">
                      <CertificateTable
                        :key="f.id"
                        :certificates="
                          f.filecertificates_set.map(e => e.certificate)
                        "
                      ></CertificateTable>
                      <CrlTable
                        :key="f.id"
                        :crls="f.filecrls_set.map(e => e.crl)"
                        :local="local"
                      ></CrlTable>
                    </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </template>
            <template v-else>
              No Files
            </template>
          </template>
        </template>
        <template v-else>
          <strong>Error {{ item.status }}:</strong> {{ item.reason }}

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on" small
                >mdi-help-circle</v-icon
              >
            </template>
            <div style="max-width:400px;">{{ item.error_content }}</div>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:item.first_time="{ item }">
        {{ item.first_time | moment("MMM D YYYY, h:mm:ss a") }}
      </template>
      <template v-slot:item.last_time="{ item }">
        {{ item.last_time | moment("MMM D YYYY, h:mm:ss a") }}
      </template>
      <template v-slot:loading>
        <LoadingSVG />
      </template>
    </v-data-table>
  </MaterialCard>
</template>

<script>
import LoadingSVG from "../LoadingSVG";
import moment from "moment";
import UserService from "../../store/UserService";
import CertificateTable from "../CertificateTable";
import MaterialCard from "../charts/MaterialCard";
import { StatusIndicator } from "vue-status-indicator";
import CrlTable from "../CrlTable";
export default {
  name: "UrlContentHistory",
  components: {
    MaterialCard,
    CertificateTable,
    LoadingSVG,
    StatusIndicator,
    CrlTable
  },
  props: ["url", "private_instance", "local"],
  data: function() {
    return {
      sortBy: "last_time",
      sortDesc: true,
      first_load: true,
      result_count: 0,
      table_items: [],
      loading: true,
      options: {
        itemsPerPage: 5,
        page: 1,
        include: "both",
        changes_only: true
      },
      table_headers: [
        { text: "Status", value: "available" },
        {
          text: "File",
          sortable: true,
          value: "file"
        },
        { text: "First Seen", value: "first_time", sortable: true },
        { text: "Last", value: "last_time", sortable: true }
      ]
    };
  },
  computed: {},
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    },
    setData: function(response) {
      if (this.first_load) {
        this.$emit("load-events", response.data.results);
        this.first_load = false;
      }
      this.result_count = response.data.count;
      this.table_items = response.data.results;
      this.loading = false;
    }
  },
  mounted: function() {},
  watch: {
    options: {
      handler() {
        let params =
          "&page=" + this.options.page + "&size=" + this.options.itemsPerPage;
        if (this.options.sortBy.length > 0) {
          if (this.options.sortDesc[0]) {
            params += "&ordering=-" + this.options.sortBy;
          } else {
            params += "&ordering=" + this.options.sortBy;
          }
        }
        if (this.options.include === "available") {
          params += "&available=True";
          if (this.options.changes_only) {
            params += "&content_change=True";
          }
        } else if (this.options.include === "unavailable") {
          params += "&available=False";
        }
        this.loading = true;

        if (this.private_instance && !this.url.is_private) {
          let url = "uriavailability/?sha1=" + this.url.sha1 + params;
          UserService.getRemoteApi(url).then(response => {
            this.setData(response);
          });
        } else {
          let url = "uriavailability/?uri_id=" + this.url.id + params;
          UserService.getLocalApi(url).then(response => {
            this.setData(response);
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
