<template>
  <div>
    <v-btn
      :to="to_link"
      class="ma-0 pa-0"
      outlined
      color="primary"
      style="height:40px;width:20px !important;float:left;border-radius: 3px 0px 0px 3px;"
      dense
    >
      <v-badge
        color="primary"
        bottom
        left
        content="CRL"
        offset-y="20"
        offset-x="28"
      >
        <v-icon large color="secondary" class="ma-0 pa-0 pl-2"
          >mdi-playlist-remove
        </v-icon>
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CrlTile",
  props: ["crl", "local"],
  data: function() {
    return {};
  },
  computed: {
    to_link: function() {
      if (this.local) {
        return "/crl/" + this.crl.id + "/";
      } else {
        return "/crl/" + this.crl.sha1 + "/";
      }
    }
  },
  methods: {}
};
</script>
